import React from 'react';

import { Section, Container, Text, Quote } from '@components/global';
import ExternalLink from '@common/ExternalLink';

const Page = () => (
  <Section id="about" dark>
    <Container>
      <Text>
        <Quote>
          “This is your life and its ending one moment at a time.” ― Chuck
          Palahniuk, Fight Club
        </Quote>
        <p>
          <b>Lead Product Designer</b> at{' '}
          <ExternalLink regular href="https://www.pigment.com/">
            Pigment
          </ExternalLink>
          , enabling people and organizations to make better decisions. Former 
          Product Designer at{' '}
          <ExternalLink regular href="https://www.birdeatsbug.com/">
            Bird Eats Bug
          </ExternalLink>
          , now part of BrowserStack, and{' '}
          <ExternalLink regular href="https://www.telekom.com/">
            Deutsche Telekom
          </ExternalLink>
          .
        </p>
        <p>
          I studied Engineering in Telecommunications and worked as a consultant
          to managers at T-Systems for more than 10 years, before making the
          jump into Product Design.{' '}
        </p>
        <p>
          With professional experiences in different industries (Public Sector,
          Automotive, Software Factory), collaborating with C-level executives
          and projects on many business functions (HR, Finance, Sales,
          Marketing, Legal, etc.).
        </p>

        <p>
          <ExternalLink href="https://linkedin.com/in/aitorperez">
            Go check my LinkedIn
          </ExternalLink>
        </p>
      </Text>
    </Container>
  </Section>
);

export default Page;
