import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import { ReactComponent as LockIcon } from './svg/lock.svg';

import ExternalLink from '@common/ExternalLink';
import { useScrollPosition } from '@components/hooks/useScrollPosition';

import { AwesomeButton } from 'react-awesome-button';
import 'react-awesome-button/dist/styles.css';
import './design.css';

import {
  Section,
  Container,
  Heading,
  Text,
  DesignText,
  TechnologyText,
  Quote,
} from '@components/global';

import Portfolio from './Portfolio';

const NUM_LAYERS = 5; // 11
const NUM_FRAMES = 7; // 6

const Page = () => {
  const windowHeight = typeof window !== 'undefined' ? window.innerHeight : 0;
  const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  const isPortrait = windowHeight > windowWidth ? true : false;
  const elementRef = useRef();
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const [step, setStep] = useState(0);

  useEffect(() => {
    const offset = isPortrait ? windowHeight / 6 : windowHeight / 5;
    const cut = isPortrait ? 0.15 : 0.5;
    const threshold = windowHeight * cut - offset;
    const start = isPortrait ? windowHeight : windowHeight - 2 * offset;
    const relativePos = (start - pos.y) / (start - threshold);
    /*console.log(
      `window height:${window.innerHeight}, start:${start}, threshold:${threshold}, y:${pos.y} and relative:${relativePos}`
    );*/
    const calculatedStep = Math.max(
      0,
      Math.min(NUM_FRAMES, Math.round(NUM_FRAMES * relativePos, 0))
    );

    //console.log(calculatedStep);
    setStep(calculatedStep);
  }, [pos, windowHeight, isPortrait]);

  // Element scroll position
  useScrollPosition(
    ({ currPos }) => {
      //console.log(currPos);
      //console.log(window.innerHeight);
      setPos(currPos);
    },
    [],
    elementRef
  );
  return (
    <StaticQuery
      query={graphql`
        query {
          work: allFile(
            filter: { sourceInstanceName: { eq: "work" } }
            sort: { fields: name, order: ASC }
          ) {
            edges {
              node {
                id
                name
                childImageSharp {
                  fluid(maxHeight: 960, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          screens: allFile(
            filter: { sourceInstanceName: { eq: "screens" } }
            sort: { fields: name, order: ASC }
          ) {
            edges {
              node {
                id
                name
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Section id="designer-and-technologist">
          <Container>
            <Heading>
              <h2>
                <DesignText>Some stuff I designed, </DesignText>
                <TechnologyText>and built or shipped.</TechnologyText>
              </h2>
            </Heading>

            <Text>
              <Quote>
                “Any sufficiently advanced technology is indistinguishable from
                magic.” ― Arthur C. Clarke
              </Quote>
              <p>
                My day to day as a Lead Designer at{' '}
                <ExternalLink regular href="https://www.pigment.com/">
                  Pigment
                </ExternalLink>
                {' '}involves taking ownership of sensible projects 
                that reach across multiple domains of a planning platform built 
                for the agility of today's world's changes and the scale of 
                large enterprises, while leading high-impact and strategic initiatives.
              </p>
            </Text>
          </Container>

          <Grid>
            {data.work.edges.map(({ node }, index) => {
              return <Art key={node.id} fluid={node.childImageSharp.fluid} />;
            })}
          </Grid>

          <Container>
            <Text>
              <p>
                Previously designed and delivered features and
                enhancements to widen the reach and drive user engagement on a SaaS
                product for software teams, and new solutions to replace or
                build upon existing citizen-facing software solutions for Local
                Governments in Spain.
              </p>
              <p>
                On a mission to leverage modern technology stacks, machine
                learning, design practices and data-driven decisions to build
                things and improve user experiences.
              </p>
            </Text>
          </Container>

          <LayeredGrid ref={elementRef}>
            {data.screens.edges.map(({ node }, index) => {
              return (
                <Layer
                  key={node.id}
                  position={index}
                  offset={-(NUM_LAYERS - 1) / 2 + index}
                  step={step}
                >
                  <LayerArt fluid={node.childImageSharp.fluid} />
                </Layer>
              );
            })}
          </LayeredGrid>

          <Container>
            <PortfolioHeader>
              <h3>
                <DesignText>Portfolio</DesignText>
              </h3>
            </PortfolioHeader>

            <PortfolioGrid>
              {Portfolio.map(
                (
                  { title, description, year, isPlaceholder, isPrivate },
                  index
                ) => {
                  return (
                    <PortfolioElement key={title} isPlaceholder={isPlaceholder}>
                      <PortfolioHeading>{title}</PortfolioHeading>
                      {year && <PortfolioYear>{year}</PortfolioYear>}
                      <PortfolioParagraph>{description}</PortfolioParagraph>
                      {!isPlaceholder && !isPrivate && (
                        <Link
                          to={`/design/${title
                            .replace(/\s+/g, '-')
                            .toLowerCase()}`}
                        >
                          <AwesomeButton type="primary" ripple>
                            See more
                          </AwesomeButton>
                        </Link>
                      )}
                      {isPrivate && (
                        <>
                          <PortfolioLockIcon />
                          <br />
                          <PortfolioHelp>
                            This project is private, please ask me about it
                          </PortfolioHelp>
                        </>
                      )}
                    </PortfolioElement>
                  );
                }
              )}
            </PortfolioGrid>
          </Container>
        </Section>
      )}
    />
  );
};

// Grid

const Grid = styled.div`
  display: grid;
  //grid-template-columns: 3fr 3fr;
  grid-template-columns: 2fr 3fr 2fr 3fr;
  grid-gap: 40px;
  text-align: center;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  @media (max-width: ${(props) => props.theme.screen.lg}) {
    //grid-template-columns: 1fr;
    grid-template-columns: 2fr 3fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 2fr;
  }
`;

const Art = styled(Img)`
  width: 100%;
`;

// Stacked grid

/*
const StackedGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  text-align: center;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }
  }
`;*/

// Layered grid

const SCALE = 1; // 2
const COLUMNS = (NUM_LAYERS + SCALE - 1) * NUM_FRAMES; // 72

const LayeredGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${COLUMNS}, 1fr);
  grid-template-row: repeat(${COLUMNS}, 1fr);
  grid-gap: 4px;
  text-align: center;
  align-items: center;
  justify-items: center;
  margin: 24px 0;
`;

const WIDTH = NUM_FRAMES * SCALE; // 12
const CENTER = 1 + (COLUMNS - WIDTH) / 2; // 31

const Layer = styled.div`
  width: 100%;
  grid-column: ${(props) =>
    `${CENTER + props.offset * props.step} / span ${WIDTH}`};
  grid-row: 1;
  z-index: ${(props) => 25 - Math.abs(props.offset)};
  //transition: .3s ease-in-out;
  transform: perspective(100px)
    translate3D(
      0,
      ${(props) => Math.abs(props.offset) * props.step * -0.05}%,
      ${(props) => Math.abs(props.offset) * props.step * -2}px
    );
  @media (max-width: ${(props) => props.theme.screen.md}) {
    //grid-row: ${(props) =>
      `${1 + props.offset * props.step} / span ${WIDTH}`};
    grid-row: 1;
    grid-column: ${(props) => `5 / span ${COLUMNS - 8}`};
    z-index: ${(props) => 25 + Math.abs(props.position)};
    transform: none;
    transition: opacity 0.3s ease-in-out;
    opacity: ${(props) =>
      props.position + 1 + NUM_FRAMES - NUM_LAYERS ===
      Math.max(NUM_FRAMES - NUM_LAYERS + 1, props.step)
        ? 1
        : 0};
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-column: ${(props) => `3 / span ${COLUMNS - 4}`};
  }
`;

const LayerArt = styled(Img)`
  //width: 100%;
  //height: 100%;
`;

// Portfolio grid

const PortfolioHeader = styled.div`
  justify-self: center;
  text-align: center;
  padding: 6rem 6rem 0 6rem;

  position: relative;
  z-index: 100;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: start;
    padding: 3rem 1.5rem 0 1.5rem;
  }
`;

const PortfolioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${Math.min(3, Portfolio.length)}, 2fr);
  grid-gap: 40px;
  text-align: center;
  align-items: start;
  justify-items: center;
  margin: 24px 0;

  @media (max-width: ${(props) => props.theme.screen.lg}) {
    grid-template-columns: repeat(${Math.min(2, Portfolio.length)}, 2fr);
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 2fr;
  }
`;

const PortfolioElement = styled.div`
  max-width: 240px;
  margin-bottom: 24px;
  opacity: ${(props) => (props.isPlaceholder ? '0.5' : '1')};
`;

const PortfolioHeading = styled.h5`
  margin-block-end: 6px;
`;

const PortfolioParagraph = styled.p`
  // max-width: 240px;
  margin-left: auto;
  margin-right: auto;
`;

const PortfolioYear = styled.p`
  margin-left: auto;
  margin-right: auto;
  font-size: 1em;
  margin-bottom: 8px;
`;

const PortfolioLink = styled(Link)`
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.small};
  text-decoration: none;
  color: ${(props) => props.theme.color.black.light};
  background: ${(props) => props.theme.color.primary};
  padding: 0.75rem;
  border-radius: 0.5rem;
  &:hover {
    background: ${(props) => props.theme.color.black.light};
    color: ${(props) => props.theme.color.white.regular};
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
      rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
      rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }
`;

const PortfolioLockIcon = styled(LockIcon)`
  height: 48px;
  background: ${(props) => props.theme.color.black.light};
  padding: 10px;
  border-radius: 50%;
  & .lock-handle {
    fill: ${(props) => props.theme.color.primary};
  }
  & .lock-body {
    fill: ${(props) => props.theme.color.primary};
  }
`;

const PortfolioHelp = styled.small`
  opacity: 0.5;
  padding: 0 36px;
  display: block;
  @media (max-width: ${(props) => props.theme.screen.lg}) {
    padding: 0;
    padding-right: 64px;
  }
`;

export default Page;
